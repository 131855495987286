<template>
    <div
        v-if="image || video"
        class="hero-home"
    >
        <ImageAsset
            v-if="!showVideo"
            class="hero-home__image"
            :class="imageMobile ? 'hero-home__image--desk' : ''"
            :image="image"
            sizes="100vw"
        />
        <ImageAsset
            v-if="!showVideo && imageMobile"
            class="hero-home__image hero-home__image--mob"
            :image="imageMobile"
            :max="980"
            sizes="100vw"
        />
        <BaseVideo
            v-if="video"
            ref="video"
            :video-url="video"
            :video-url-mobile="videoMobile"
            :poster="image.url"
            :autoplay="true"
            class="hero-home__video"
        />
        <div class="hero-home__text">
            <h1 class="hero-home__title">{{ heroTitle }}</h1>
            <div
                v-if="subTitle"
                class="hero-home__sub-title"
            >
                {{ subTitle }}
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: null
    },
    subTitle: {
        type: String,
        default: ''
    },
    image: {
        type: Object,
        default() {
            return {};
        }
    },
    imageMobile: {
        type: Object,
        default() {
            return {};
        }
    },
    showVideo: {
        type: Boolean,
        default: false
    },
    video: {
        type: String,
        default: ''
    },
    videoMobile: {
        type: String,
        default: ''
    }
});

const { title } = toRefs(props);

const config = useRuntimeConfig();
const heroTitle = computed(() => title.value ?? config.public.defaults.siteTitle);
</script>

<style lang="less" src="./Hero.less" />
